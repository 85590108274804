<template>
  <v-container>
    <v-row justify="center" dense>
      <v-col sm="11" md="11" lg="11" xl="11">
        <v-card v-if="!cargando">
          <v-banner
            width="100%"
            color="#0089B5"
            rounded
            dark>
            <h2 class="text-center">Requerimiento No. {{ $route.params.id }}</h2>
          </v-banner>
          <v-card-text>
            <v-container>
              <v-row justify="center" dense>
                <v-col sm="12" md="12" lg="12" xl="12">
                  <span class="titleslight--text font-weight-medium">Fecha Registro:</span>
                  <v-text-field
                    v-model="fecha"
                    readonly
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col sm="6" md="6" lg="6" xl="6">
                  <span class="titleslight--text font-weight-medium">Cliente:</span>
                  <v-text-field
                    v-model="cliente"
                    readonly
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
                <v-col sm="6" md="6" lg="6" xl="6">
                  <span class="titleslight--text font-weight-medium">Sede:</span>
                  <v-text-field
                    v-model="sede"
                    readonly
                    outlined
                    dense>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center" dense>
                <v-col sm="12" md="12" lg="12" xl="12">
                  <span class="titleslight--text font-weight-medium">Observacion:</span>
                  <v-textarea
                    v-model="observacion"
                    readonly
                    outlined
                    dense>
                  </v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-text v-for="(oForm, i) in aForm" :key="i">
            <Bloques
              :title="oForm.BannerTitle">
            </Bloques>
            <v-container>
              <v-row justify="center" dense>
                <v-col sm="12" md="6" lg="6" xl="6" v-for="(aData, k) in oForm" :key="k">
                  <component :is="aData.tipocampo"
                    v-model="oFormCampos[aData.idPregunta]"
                    :titulo="aData.Pregunta"
                    :obligatorio="aData.obligatorio == 1 ? true : false"
                    :itemsData="aData.items"
                    :enable="true">
                  </component>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="cargando" persistent width="200">
      <v-card
        color="primary"
        dark>
        <v-card-title>
          Cargando...
        </v-card-title>
        <v-card-text style="text-align: center;">
          <v-progress-circular
            active
            indeterminate
            color="white"
            class="mb-0">
          </v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axiosServices from '@/services/axiosServices.js'
import Bloques from '@/components/FormDinamicos/Componentes/Bloque.vue'
import Select from '@/components/FormDinamicos/ComponentesPQR/Select.vue'
import MultiSelect from '@/components/FormDinamicos/ComponentesPQR/Multiselect.vue'
import CheckBox from '@/components/FormDinamicos/ComponentesPQR/CheckBox.vue'
import EditTextNumeros from '@/components/FormDinamicos/ComponentesPQR/TextNumeric.vue'
import EditTextTexto from '@/components/FormDinamicos/ComponentesPQR/Text.vue'
import EditTextAlfanumerico from '@/components/FormDinamicos/ComponentesPQR/TextAlfaNumeric.vue'
import Calendarios from '@/components/FormDinamicos/ComponentesPQR/Calendario.vue'
import RadioButton from '@/components/FormDinamicos/ComponentesPQR/RadioButton.vue'
export default {
  components:{
    Bloques,
    Select,
    MultiSelect,
    CheckBox,
    EditTextNumeros,
    EditTextTexto,
    EditTextAlfanumerico,
    Calendarios,
    RadioButton,
  },
  data() {
    return {
      fecha: '',
      cliente: '',
      sede: '',
      observacion: '',
      cargando: false,
      aForm: [],                          // arreglo de campos del formulario  
      oFormCampos: {},
    }
  },
  mounted() {
    this.loadDataEdit()
    this.getFieldForm()
    this.getPQR()
  },
  methods: {
    loadDataEdit(){
      this.cargando = true
      axiosServices.getConfiguration(`FormDinamicPQR_item/${this.$route.params.id}`, false, '').then(aItemData =>{
        this.cargando = false
        if(aItemData.aData.length !== 0){
          this.oFormCampos = aItemData.aData[0]
        }
      })
    },
    getFieldForm() {
      this.cargando = true
      axiosServices.getConfiguration(`FormDinamicPQR/${this.$route.params.id}`, false, '').then(getForm => {
        this.aForm = getForm.aData
        this.cargando = false
      })
    },
    getPQR() {
      this.cargando = true
      axiosServices.getConfiguration(`FormDinamicPQR_getpqr/${this.$route.params.id}`, false, '').then(getPQR => {
        this.fecha = getPQR.aData.Fecha
        this.cliente = getPQR.aData.Nombre_Cliente
        this.sede = getPQR.aData.Nombre
        this.observacion = getPQR.aData.Requerimiento
      })
    }
  },
}
</script>